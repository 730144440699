<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" sm="7">
        <BaseCard heading="Lista de Referidos">
            <v-data-table
                :headers="headersReferidos"
                :items="listReferidos"
                item-key="name"
                sort-by="['nivel', 'name']"
                group-by="nivel"
                class="elevation-0 mt-0"
                show-group-by
                loading-text="Loading... Please wait"
            >
                <template v-slot:[`item.name`]="{ item }">
                    <div class="d-flex align-center">
                        <v-avatar>
                        <img
                            :src="item.avatar"
                            :title="item.name"
                            onerror="this.src='nouser.jpg'"
                        />
                        </v-avatar>
                        <div class="ma-2">
                        <h4 class="font-weight-medium text-no-wrap subtitle-1">
                            {{ item.name }}
                        </h4>
                        <h6 class="blue-grey--text text-no-wrap text--lighten-2 font-weight-regular subtitle-2" style="margin-top: -10px;">
                            {{ item.email }}
                        </h6>
                        </div>
                    </div>
                </template>
            </v-data-table>
            <!-- ----------------------------------------------------------------------------- -->
            <!-- TableSimpleFixHeader -->
            <!-- ----------------------------------------------------------------------------- -->
                <!-- <div class="mt-0">
                    <v-simple-table fixed-header height="415px">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="font-weight-medium subtitle-1">Identificación</th>
                            <th class="font-weight-medium subtitle-1">Total invertido</th>
                            <th class="font-weight-medium subtitle-1">Estructura</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="referido in listReferidos" :key="referido.id"
                                class="month-item"
                            >
                                <td class="py-3 px-2">
                                <div class="d-flex align-center">
                                    <v-avatar>
                                    <img
                                        :src="referido.avatar"
                                        :title="referido.name"
                                    />
                                    </v-avatar>
                                    <div class="ml-3">
                                    <h4
                                        class="font-weight-medium text-no-wrap subtitle-1"
                                    >
                                        {{ referido.name }}
                                    </h4>
                                    <h6
                                        class="blue-grey--text text-no-wrap text--lighten-2 font-weight-regular subtitle-2"
                                    >
                                        {{ referido.email }}
                                    </h6>
                                    </div>
                                </div>
                                </td>
                                <td>
                                <h5
                                    class="font-weight-light text-no-wrap subtitle-2 body-1"
                                >
                                    {{ referido.created_at }}
                                </h5>
                                </td>
                                <td>
                                <v-chip
                                    class="ma-2"
                                    :class="referido.statuscolor"
                                    small
                                    >{{ referido.statustext }}</v-chip
                                >
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </div> -->
        </BaseCard>
      </v-col>
      <v-col cols="12" sm="5">
        <BaseCard heading="Mi Link de Referencia">
            <div class="mt-0">
                <v-card-text>
                    <v-text-field
                    v-model="linkrefer"
                    background-color="transparent"
                    type="text"
                    name="input-10-1"
                    >
                        <template v-slot:append>
                            <v-btn
                                class="mb-1"
                                fab
                                dark
                                small
                                color="success"
                                title="Copiar"
                                @click="copy(linkrefer)"
                            >
                            <v-icon dark>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-card-text>
            </div>
        </BaseCard>

        <BaseCard heading="Socios Totales">
            <div class="mt-0">
                <v-simple-table dense>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">Nivel</th>
                        <th class="text-left">Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listSocios" :key="item.cant">
                        <td>Socios en el nivel {{ item.nivel }}</td>
                        <td>{{ item.cant }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div>
        </BaseCard>
      </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import VsToast from '@vuesimple/vs-toast'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

export default {
  name: "Referidos",

  data: () => ({
    linkrefer: 'https://ticktack.com/?refer=',
    usrToken: '',
    usrData: {},
    listSocios: [],
    listReferidos: [],
    dialog: false,
    headersReferidos: [
        { text: 'Identificación', align: 'start', value: 'name', groupable: false },
        { text: 'Nivel', value: 'nivel', align: 'right' },
        { text: 'Total Invertido', value: 'total_inversion', align: 'right', groupable: false },
        { text: 'Estructura', value: 'estructura', align: 'right', groupable: false },
    ],
  }),

  components: {
    sesionExpiredDialog
  },
  
  methods: {
    async copy(mytext) {
        var input = document.createElement('input');
        input.setAttribute('value', mytext);
        input.value = mytext;        
        document.body.appendChild(input);
        try {
            input.select();    
            input.click();     
            input.focus();
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            if(successful){
                VsToast.show({
                    //title: this.titleRechazar,
                    message: 'Texto copiado...',
                    variant: 'success',
                    timeout: 2000,
                    type: "alert"
                });
            }
            console.log('Testing code was copied ' + successful + ' ' + msg);
            
        } catch (err) {
            console.log('Oops, unable to copy');
        }
        document.body.removeChild(input);          
    }

  },

  async created () {
    this.usrData = await JSON.parse(localStorage.getItem("user"))
    this.usrToken = localStorage.getItem("usrToken")
    this.linkrefer = process.env.VUE_APP_WEB_URL + '/?refer=' + this.usrData.co_user
    //this.monto_inicial = Number(process.env.VUE_APP_MONTO_INICIAL)
    console.log('Dashboard Created')
    //console.log('Revisar Inicio de Sesión Resp: ')
    
    //Obtener la informacion de los socios
    for(let i=1; i<=3; i++){
        axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/referidos_${i}`,
            headers: { 
                "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                //"Accept": "application/json"
            }
        })
        .then(async response => {
            //this.loading = false
            let respReferidos = response.data
            //console.log('Withdrawals...', respBalance);
            if (!respReferidos.success){
                console.error(i, ' - respReferidos Error: ', respReferidos)
            }
            else {
                //this.listPlanes = this.formatMontosPlanes(respReferidos.data)
                for(let r=0; r < respReferidos.data.length; r++){
                    respReferidos.data[r].nivel = i;
                    respReferidos.data[r].avatar = !respReferidos.data[r].avatar ? 'nouser.jpg' : respReferidos.data[r].avatar;
                    respReferidos.data[r].estructura = respReferidos.data[r].count_referidos1 + '|' + respReferidos.data[r].count_referidos2
                    respReferidos.data[r].total_inversion = '$' + respReferidos.data[r].total_inversion.toLocaleString("es-VE");
                    this.listReferidos.push(respReferidos.data[r])
                }
                this.listSocios.push({
                    nivel: i,
                    cant: respReferidos.count
                })
                this.listSocios.sort((a, b) => a.nivel-b.nivel)
            }
        })
        .catch(error => {
            console.error(i, ' - respReferidos Error: ', error)
            this.loading = false;
            //let message = !error.response ? error.message : error.response.data.msg;
            if(error.response && error.response.statusText == "Unauthorized"){
                this.dialog = true
            }
        })
    }
  },
};
</script>

<style>
.theme--light.v-data-table .v-row-group__header, .theme--light.v-data-table .v-row-group__summary {
    background: #e8fdeb;
}
</style>